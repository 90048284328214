import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Table from "../../reusable/Table/Table";
import TableHead from "../../reusable/Table/TableHead";
import TableCell from "../../reusable/Table/TableCell";
import DateInput from "../../reusable/DateInput";
import DateUtils from "../../../services/utility/DateUtils";
import { Check, X, AlertCircle } from "lucide-react";
import { Modal, message } from "antd";
import Trip from "../../../services/api/tripRequestApi";
import DropDown from "../../reusable/DropDown";
import { History, Download } from "lucide-react";

export default function NotAllotedTable({
  setFromDate,
  setToDate,
  fromDate,
  toDate,
  tripList,
  fetchTripData,
  tripStatus,
  setTripType,
  tripType,
  fetchTripCount,
}) {
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [rejectionReason, setRejectionReason] = useState("");
  const [rejectionError, setRejectionError] = useState("");
  const [showRevertModal, setShowRevertModal] = useState(false);

  const downloadExcel = () => {
    // Create formatted data for Excel
    const excelData = tripList.map((row) => ({
      "Employee ID": row?.employee?.employeeID || "",
      "Employee Name": row?.employee?.employeeName || "",
      "Request Date": DateUtils.convertDate(row.requestDate) || "",
      "Shift Time": row?.shiftTime?.name || "",
      "Trip Type": row?.tripType?.name || "",
      "Trip Date": DateUtils.convertDate(row.tripDate) || "",
      "Nodal Point": row.nodalPoint || "",
      Status:
        tripStatus === 0
          ? "Pending"
          : tripStatus === 1
          ? "Approved"
          : "Rejected",
      Reason: row?.reason || "",
    }));

    // Convert data to CSV format
    const headers = Object.keys(excelData[0]);
    const csvContent = [
      headers.join(","),
      ...excelData.map((row) =>
        headers.map((header) => `"${row[header]}"`).join(",")
      ),
    ].join("\n");

    // Create and download the file
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `trip_requests_${DateUtils.convertDate(new Date())}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleRevert = async (row) => {
    const data = {
      id: row.id,
      status: 0, // Reset to pending/initial status
    };

    try {
      await Trip.updateStatus(data);
      message.success("Trip request reverted successfully");
      setShowRevertModal(false);
      setSelectedRow(null);
      fetchTripData();
      fetchTripCount();
    } catch (error) {
      console.error(error);
      message.error("Failed to revert trip request");
    }
  };

  const columns = [
    {
      id: "employeeId",
      name: <TableHead width="100px">Employee ID</TableHead>,
      cell: (row) => (
        <TableCell width="100px">{row?.employee?.employeeID}</TableCell>
      ),
    },
    {
      id: "employeeName",
      name: <TableHead width="150px">Employee Name</TableHead>,
      cell: (row) => (
        <TableCell width="150px">{row?.employee?.employeeName}</TableCell>
      ),
    },
    {
      id: "requestDate",
      name: <TableHead width="100px">Request Date</TableHead>,
      cell: (row) => (
        <TableCell width="100px">
          {DateUtils.convertDate(row.requestDate)}
        </TableCell>
      ),
    },
    {
      id: "shiftTime",
      name: <TableHead width="100px">Shift Time</TableHead>,
      cell: (row) => (
        <TableCell width="100px" textCenter>
          {row?.shiftTime?.name}
        </TableCell>
      ),
    },
    {
      id: "tripType",
      name: <TableHead width="80px">Trip Type</TableHead>,
      cell: (row) => <TableCell width="70px">{row?.tripType?.name}</TableCell>,
    },
    {
      id: "tripDate",
      name: <TableHead width="120px">Trip Date</TableHead>,
      cell: (row) => (
        <TableCell width="120px">
          {DateUtils.convertDate(row.tripDate)}
        </TableCell>
      ),
    },
    {
      id: "nodalPoint",
      name: <TableHead width="70px">Nodal Point</TableHead>,
      cell: (row) => (
        <TableCell width="70px">
          <div className="relative group cursor-pointer">
            <div className="truncate">{row.nodalPoint}</div>
            <div className="hidden group-hover:block absolute z-10 bg-gray-900 text-white p-2 rounded-md text-sm whitespace-normal">
              {row.nodalPoint}
            </div>
          </div>
        </TableCell>
      ),
    },
    ...(tripStatus === 2
      ? [
          {
            id: "action",
            name: <TableHead width="150px">Reason</TableHead>,
            cell: (row) => (
              <TableCell width="150px">
                <div className="relative group cursor-pointer">
                  <div className="truncate text-red-500">{row?.reason}</div>
                  <div className="hidden group-hover:block absolute z-10 bg-gray-900 text-white p-2 rounded-md text-sm whitespace-normal max-w-xs">
                    {row?.reason}
                  </div>
                </div>
              </TableCell>
            ),
          },
        ]
      : []),
    ...(tripStatus === 0
      ? [
          {
            id: "action",
            name: <TableHead width="150px">Action</TableHead>,
            cell: (row) => (
              <TableCell width="150px" className="flex gap-2">
                <div className="flex gap-2">
                  <button
                    className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors bg-green-500 text-white hover:bg-green-600 h-8 w-8"
                    onClick={() => {
                      setSelectedRow(row);
                      setShowApproveModal(true);
                    }}
                  >
                    <Check className="h-4 w-4" />
                  </button>
                  <button
                    className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors bg-red-500 text-white hover:bg-red-600 h-8 w-8"
                    onClick={() => {
                      setSelectedRow(row);
                      setShowRejectModal(true);
                    }}
                  >
                    <X className="h-4 w-4" />
                  </button>
                </div>
              </TableCell>
            ),
          },
        ]
      : [
          {
            id: "action",
            name: <TableHead width="150px">Action</TableHead>,
            cell: (row) => (
              <TableCell width="150px" className="flex gap-2">
                <div className="flex gap-2">
                  <button
                    className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors bg-blue-700 text-white hover:bg-blue-600 h-8 w-8"
                    onClick={() => {
                      setSelectedRow(row);
                      setShowRevertModal(true);
                    }}
                  >
                    <History className="h-4 w-4" />
                  </button>
                </div>
              </TableCell>
            ),
          },
        ]),
  ];
  const handleApprove = (row) => {
    console.log(`Approved request for Employee ID: ${row.employeeId}`);
    message.success("Trip request approved successfully");
    setShowApproveModal(false);
    setSelectedRow(null);
    const data = {
      id: row.id,
      status: 1,
    };
    try {
      Trip.updateStatus(data);
      fetchTripData();
      fetchTripCount();
    } catch (error) {
      console.log(error);
    }
  };

  const handleReject = (row) => {
    if (!rejectionReason.trim()) {
      setRejectionError("Please provide a reason for rejection");
      return;
    }

    const data = {
      id: row.id,
      status: 2,
      reason: rejectionReason,
    };

    try {
      Trip.updateStatus(data);
      message.error("Trip request rejected");
      setShowRejectModal(false);
      setSelectedRow(null);
      setRejectionReason("");
      setRejectionError("");
      fetchTripData();
      fetchTripCount();
    } catch (error) {
      console.log(error);
    }
  };

  const tripTypeList = [
    { name: "Select All", id: "" },
    { name: "Pickup", id: 1 },
    { name: "Drop", id: 2 },
  ];
  return (
    <div className="w-full removeScrollBar pb-5">
      <div className="flex flex-wrap lg:gap-x-8 gap-2 w-full justify-center lg:flex-wrap lg:pl-2 lg:justify-start lg:pr-10 items-center mb-5 lg:mb-4 mt-2">
        <DateInput
          label={"From date"}
          placeholder={"Select from date"}
          value={fromDate}
          setValue={setFromDate}
          setLocalStorage={"tripFromDate"}
        />
        <DateInput
          label={"To date"}
          placeholder={"Select to date"}
          value={toDate}
          setValue={setToDate}
          setLocalStorage={"tripToDate"}
        />
        <DropDown
          label={"Trip type"}
          placeholder={"Select trip type"}
          displayValue={"name"}
          data={tripTypeList}
          value={tripType}
          setValue={setTripType}
          // mandate={true}
          // error={tripTypeError}
        />
        {tripStatus === 1 && (
          <button
            onClick={downloadExcel}
            className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors bg-blue-700 text-white hover:bg-blue-600 h-10 px-4 py-2 mt-6"
          >
            <Download className="h-4 w-4 mr-2" />
            Download Excel
          </button>
        )}
      </div>

      <Table columns={columns} rows={tripList} />

      {/* Approve Confirmation Modal */}
      <Modal
        title={
          <div className="flex items-center gap-2">
            <Check className="h-5 w-5 text-green-500" />
            <span>Confirm Approval</span>
          </div>
        }
        open={showApproveModal}
        onOk={() => handleApprove(selectedRow)}
        onCancel={() => setShowApproveModal(false)}
        okText="Approve"
        cancelText="Cancel"
        okButtonProps={{
          style: { backgroundColor: "#22c55e", borderColor: "#22c55e" },
        }}
      >
        <p>
          Are you sure you want to approve the trip request for{" "}
          {selectedRow?.employee?.employeeName} (
          {selectedRow?.employee?.employeeID})?
        </p>
      </Modal>

      {/* Reject Confirmation Modal */}
      <Modal
        title={
          <div className="flex items-center gap-2">
            <X className="h-5 w-5 text-red-500" />
            <span>Confirm Rejection</span>
          </div>
        }
        open={showRejectModal}
        onOk={() => handleReject(selectedRow)}
        onCancel={() => {
          setShowRejectModal(false);
          setRejectionReason("");
          setRejectionError("");
        }}
        okText="Reject"
        cancelText="Cancel"
        okButtonProps={{
          style: { backgroundColor: "#ef4444", borderColor: "#ef4444" },
        }}
      >
        <div className="space-y-4">
          <p>
            Are you sure you want to reject the trip request for{" "}
            {selectedRow?.employee?.employeeName} (
            {selectedRow?.employee?.employeeID})?
          </p>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Rejection Reason *
            </label>
            <textarea
              value={rejectionReason}
              onChange={(e) => {
                setRejectionReason(e.target.value);
                setRejectionError("");
              }}
              className="w-full p-2 border rounded-md focus:ring-red-500 focus:border-red-500"
              rows={3}
              placeholder="Please provide a reason for rejection"
            />
            {rejectionError && (
              <p className="mt-1 text-sm text-red-600">{rejectionError}</p>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        title={
          <div className="flex items-center gap-2">
            <History className="h-5 w-5 text-blue-500" />
            <span>Confirm Revert</span>
          </div>
        }
        open={showRevertModal}
        onOk={() => handleRevert(selectedRow)}
        onCancel={() => setShowRevertModal(false)}
        okText="Revert"
        cancelText="Cancel"
        okButtonProps={{
          style: { backgroundColor: "#3b82f6", borderColor: "#3b82f6" },
        }}
      >
        <p>
          Are you sure you want to revert the trip request for{" "}
          {selectedRow?.employee?.employeeName} (
          {selectedRow?.employee?.employeeID})? This will reset the request to
          its initial state.
        </p>
      </Modal>
    </div>
  );
}
