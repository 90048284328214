import React, { useState, useEffect } from "react";
import { Modal, Table, message, Space, Popconfirm, Input } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Button from "../../components/reusable/Button";
import Trip from "../../services/api/tripRequestApi";

const NodalPointModal = ({ isVisible, onClose }) => {
  const [nodalPoints, setNodalPoints] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [newNodalPoint, setNewNodalPoint] = useState("");
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (isVisible) {
      fetchNodalPoints();
    }
  }, [isVisible]);

  const fetchNodalPoints = async () => {
    try {
      setLoading(true);
      const data = await Trip.getAllNodalPoint();
      setNodalPoints(data);
    } catch (error) {
      message.error("Failed to fetch nodal points");
      console.error("Error fetching data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleAddNodalPoint = async () => {
    if (!newNodalPoint.trim()) {
      message.warning("Please enter a nodal point name");
      return;
    }

    try {
      setSubmitLoading(true);
      const data = {
        name: newNodalPoint.trim(),
      };

      const response = await Trip.createNodalPoint(data);

      if (response) {
        message.success("Nodal point added successfully");
        setAddModalVisible(false);
        setNewNodalPoint("");
        fetchNodalPoints();
      } else {
        throw new Error("Failed to add nodal point");
      }
    } catch (error) {
      message.error("Failed to add nodal point");
      console.error("Error adding nodal point:", error);
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleDeleteNodalPoint = async (id) => {
    try {
      setLoading(true);
      const response = await Trip.deleteNodalPoint(id);

      if (response) {
        message.success("Nodal point deleted successfully");
        fetchNodalPoints();
      } else {
        throw new Error("Failed to delete nodal point");
      }
    } catch (error) {
      message.error("Failed to delete nodal point");
      console.error("Error deleting nodal point:", error);
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Nodal Point Name",
      dataIndex: "name",
      key: "name",
    },
    {
        title: "Actions",
        key: "actions",
        width: "150px",
        render: (_, record) => (
          <Space>
            <Popconfirm
              title="Delete Nodal Point"
              description="Are you sure you want to delete this nodal point?"
              onConfirm={() => handleDeleteNodalPoint(record.id)}
              okText="Yes"
              cancelText="No"
              placement="left"
              okButtonProps={{
                className: "bg-blue-600 text-white hover:bg-blue-700"
              }}
              cancelButtonProps={{
                className: "hover:border-gray-400"
              }}
            >
              <div className="cursor-pointer text-red-500 hover:text-red-700">
                <DeleteOutlined className="text-lg mr-1" />
                <span>Delete</span>
              </div>
            </Popconfirm>
          </Space>
        ),
      }
  ];

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAddNodalPoint();
    }
  };

  const renderModalFooter = () => {
    return (
      <div className="flex justify-end space-x-4 mt-4">
        <div className="w-[120px]">
          <Button
            onClick={() => {
              setAddModalVisible(false);
              setNewNodalPoint("");
            }}
            text="Cancel"
            type="secondary"
            width="100%"
          />
        </div>
        <div className="w-[120px]">
          <Button
            onClick={handleAddNodalPoint}
            text="Add"
            loading={submitLoading}
            disabled={!newNodalPoint.trim()}
            width="100%"
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        title="Nodal Points Management"
        open={isVisible}
        onCancel={onClose}
        footer={null}
        width={800}
      >
        <div className="mb-4 flex justify-end">
          <Button
            onClick={() => setAddModalVisible(true)}
            text="Add Nodal Point"
            width="160px"
          />
        </div>

        <Table
          columns={columns}
          dataSource={nodalPoints}
          rowKey="id"
          loading={loading}
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
          }}
        />
      </Modal>

      <Modal
        title="Add New Nodal Point"
        open={addModalVisible}
        onCancel={() => {
          setAddModalVisible(false);
          setNewNodalPoint("");
        }}
        footer={renderModalFooter()}
        width={400}
      >
        <Input
          placeholder="Enter nodal point name"
          value={newNodalPoint}
          onChange={(e) => setNewNodalPoint(e.target.value)}
          onKeyPress={handleKeyPress}
          maxLength={50}
          className="mt-2"
          autoFocus
        />
      </Modal>
    </>
  );
};

export default NodalPointModal;