import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import NotAllotedTable from "../tables/trip-request/NotAllotedTable";

// Function to render the label with active state
function LabelForTabs({ children, isActive }) {
  return (
    <div
      className={`${
        isActive ? "text-primary-blue" : "text-placeholder-gray"
      } font-[420] text-[17px] `}
    >
      {children}
    </div>
  );
}

// Main component for tab navigation
function TripRequestTabNavigation({
  setFromDate,
  setToDate,
  fromDate,
  toDate,
  tripList,
  fetchTripData,
  setTripStatus,
  tripStatus,
  tripCount,
  setTripType,
  tripType,
  fetchTripCount
}) {
  const [activeKey, setActiveKey] = useState("");
  const userType = localStorage.getItem("userTypeFleetrax");
  const isAdmin = userType === "superAdminPlus" || userType === "admin" || userType === "superadmin";

  useEffect(() => {
    if (!isAdmin) {
      setActiveKey("pending");
      setTripStatus(0);
    } else {
      setActiveKey("approved");
      setTripStatus(1);
    }
  }, [isAdmin]);

  const onChange = (key) => {
    sessionStorage.setItem("tripTabNavigation", key);
    setActiveKey(key);
    const statusMap = {
      pending: 0,
      approved: 1,
      rejected: 2,
    };
    setTripStatus(statusMap[key]);
  };

  const companyItems = [
    {
      key: "pending",
      label: (
        <LabelForTabs isActive={activeKey === "pending"}>
          Pending ({tripCount?.countOfPendingTrip})
        </LabelForTabs>
      ),
      children: (
        <NotAllotedTable
          setFromDate={setFromDate}
          setToDate={setToDate}
          fromDate={fromDate}
          toDate={toDate}
          tripList={tripList}
          fetchTripData={fetchTripData}
          tripStatus={tripStatus}
          setTripType={setTripType}
          tripType={tripType}
          fetchTripCount={fetchTripCount}
        />
      ),
    },
    {
      key: "approved",
      label: (
        <LabelForTabs isActive={activeKey === "approved"}>
          Approved ({tripCount?.countOfApprovedTrip})
        </LabelForTabs>
      ),
      children: (
        <NotAllotedTable
          {...{
            setFromDate,
            setToDate,
            fromDate,
            toDate,
            tripList,
            fetchTripData,
            tripStatus,
            setTripType,
            tripType,
            fetchTripCount
          }}
        />
      ),
    },
    {
      key: "rejected",
      label: (
        <LabelForTabs isActive={activeKey === "rejected"}>
          Rejected ({tripCount?.countOfRejectedTrip})
        </LabelForTabs>
      ),
      children: (
        <NotAllotedTable
          {...{
            setFromDate,
            setToDate,
            fromDate,
            toDate,
            tripList,
            fetchTripData,
            tripStatus,
            setTripType,
            tripType,
            fetchTripCount
          }}
        />
      ),
    },
  ];
  const adminItems = [
    {
      key: "approved",
      label: (
        <LabelForTabs isActive={activeKey === "approved"}>
          Approved ({tripCount?.countOfApprovedTrip})
        </LabelForTabs>
      ),
      children: (
        <NotAllotedTable
          {...{
            setFromDate,
            setToDate,
            fromDate,
            toDate,
            tripList,
            fetchTripData,
            tripStatus,
            setTripType,
            tripType,
            fetchTripCount
          }}
        />
      ),
    },
  ];

  const items = isAdmin ? adminItems : companyItems;

  return (
    <div className="w-full h-full removeScrollBar">
      <Tabs activeKey={activeKey} items={items} onChange={onChange} />
    </div>
  );
}

export default TripRequestTabNavigation;
